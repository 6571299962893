import { TextScript, TextString } from '@MGPD/myasurion-shared/components/localization';
import { useSessionStorage } from '@MGPD/myasurion-shared/hooks';
import { useConfig } from '@MGPD/myasurion-shared/modules/config';
import { useFeature } from '@MGPD/myasurion-shared/modules/features';
import { AddIcon } from '@chakra-ui/icons';
import {
    Box,
    BoxProps,
    Button,
    Flex,
    HStack,
    Link,
    Skeleton,
    Square,
    Stack,
    Text,
    VStack,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment, ReactNode } from 'react';

import { smartScanTokenUrlKey } from '../modules/config/constants';
import { formatMdnString } from '../utils';

type DeviceStatus = {
    id: string;
    text: string;
    background: string;
    color: string;
};

const statusMap: Record<string, DeviceStatus> = {
    pending: {
        id: 'pending',
        text: 'Pending Action',
        background: '#F9D51C',
        color: 'black',
    },
    'under review': {
        id: 'under review',
        text: 'Under Review',
        background: '#D2FA46',
        // background: '#6E767D',
        color: 'black',
        // color: 'white',
    },
    approved: {
        id: 'approved',
        text: 'Approved',
        background: '#37E7A7',
        color: 'black',
    },
    processing: {
        id: 'processing',
        text: 'Processing',
        background: '#D2FA46',
        color: 'black',
    },
    // Claim Status
    draft: {
        id: 'draft',
        text: 'Draft',
        background: '#E6E6EB',
        color: 'black',
    },
    submitted: {
        id: 'submitted',
        text: 'Submitted',
        background: '#E6E6EB',
        color: 'black',
    },
    completed: {
        id: 'completed',
        text: 'Completed',
        background: '#37E7A7',
        color: 'black',
    },
    cancelled: {
        id: 'cancelled',
        text: 'Cancelled',
        background: '#FF6666',
        color: 'black',
    },
    suspended: {
        id: 'suspended',
        text: 'Suspended',
        background: '#FF6666',
        color: 'black',
    },
    backorder: {
        id: 'backorder',
        text: 'Back Order',
        background: '#E6E6EB',
        color: 'black',
    },
};

export interface DeviceCardProps {
    [index: string]: number | ReactNode | undefined | boolean;
    /**
     * MDN Plan
     * @required
     */
    plan: string;
    /**
     * MDN
     * @required
     */

    mdn: string;
    /**
     * Device model
     * @required
     */
    model: string;
    /**
     * IMEI
     * @required
     */
    imei: string;
    assetId: string;
    imageEl?: ReactNode | undefined;
    showMoreDetails?: boolean;
    detailsEl?: ReactNode | undefined;
    isLoading?: boolean;
    status?:
        | 'pending'
        | 'under review'
        | 'approved'
        | 'processing'
        | 'draft'
        | 'completed'
        | 'cancelled'
        | 'OEM_WARRANTY'
        | 'EWS'
        | 'backorder'
        | 'submitted'
        | 'suspended'; // Agreement Status

    type: 'ppe' | 'byod' | 'default';
    viewAllDeviceText?: string;
    hasUpgrade?: boolean;
    activeSRNumber?: string;
    use?: 'planOverview' | 'landing';
    // EWS dependencies
    make?: string;
    serial?: string;
    warranty?: string;
    redirectUrl?: string;
    isDocuments?: boolean;
    isAgreementSuspended?: boolean;
}

const BYODCard = ({ url }: { url: string }) => {
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    return (
        <VStack h="140px" alignItems="center" justifyContent="center">
            <Text fontSize="24px" fontWeight="light">
                No device added yet
            </Text>
            <Button
                variant="outline"
                leftIcon={<AddIcon />}
                color={colors.primary}
                borderColor={colors.primary}
                size="md"
                onClick={() => (window.location.href = url)}
            >
                Add a device
            </Button>
        </VStack>
    );
};

export const DeviceCardImageElement = ({ imageUrl }: { imageUrl: string }) => {
    return (
        <Box
            h="full"
            minH="80px"
            minW="80px"
            bgImage={`url(${imageUrl})`}
            bgRepeat="no-repeat"
            bgPosition="center center"
            bgSize="contain"
            flexGrow={1}
        />
    );
};

/**
 * Shared DeviceCard
 * @param {DeviceCardProps} args:DeviceCardProps
 */
export const DynamicDeviceCard: React.FC<DeviceCardProps & BoxProps> = (args: DeviceCardProps) => {
    // const { data } = useConfig();
    // const { theme } = data;
    // const { colors } = theme;

    const {
        plan,
        mdn,
        model,
        imei,
        assetId,
        imageEl,
        showMoreDetails,
        detailsEl,
        isLoading,
        status,
        redirectUrl,
        type = 'default',
        viewAllDeviceText,
        hasUpgrade,
        activeSRNumber,
        use = 'landing',
        isDocuments = false,
        isAgreementSuspended,
        ...props
    } = args;

    const fields: { field: string; label: string; weight?: number }[] = [
        { field: 'mdn', label: 'Mobile:', weight: 700 },
        { field: 'model', label: 'Model:' },
        { field: 'imei', label: 'IMEI:' },
    ];

    const [smartScanTokenUrl] = useSessionStorage(smartScanTokenUrlKey, '');
    const queryClient = useQueryClient();
    const getSmartScanToken: string = queryClient.getQueryData(['smartScanToken']) ?? '';
    // const { data } = useConfig();
    // const { theme } = data;
    // const { colors } = theme;
    // const [account] = useAccount<AccountInfo>({ profileId: '', userId: '' });

    const { isOn: isDeviceDetailsOn, version: deviceDetailsVersion } = useFeature('device-details');

    const primaryHeadDetail = useBreakpointValue({
        base: status ? (
            activeSRNumber ? (
                `ID: ${activeSRNumber}`
            ) : plan ? (
                <TextString text={plan} translationKey={`Global/Plan_${plan}`} />
            ) : (
                plan
            )
        ) : plan ? (
            <TextString text={plan} translationKey={`Global/Plan_${plan}`} />
        ) : (
            plan
        ),
        lg: plan ? <TextString text={plan} translationKey={`Global/Plan_${plan}`} /> : plan,
    });

    // if (isLoading) {
    //     return <Skeleton minW={'335px'} minH={'283px'} w={'full'} borderRadius={'8px'} />;
    // }

    // TODO: Apply dependency injection on this card;
    const renderCard = () => {
        // console.log('type', type);
        // console.log('status', status);
        // Render BYOD
        if (type.toLowerCase() === 'byod' && status === 'pending') {
            return <BYODCard url={smartScanTokenUrl || getSmartScanToken} />;
        }

        // Render PPE
        if (
            (type.toLowerCase() === 'ppe' && status !== 'approved') ||
            (type.toLowerCase() === 'byod' && status !== 'pending' && status !== 'approved')
        ) {
            return (
                <VStack h="full" alignItems="start" gap={0}>
                    {/* PPE Card Header */}
                    <Box m={'0'} w="full" p={'16px'}>
                        <HStack
                            w="full"
                            h="full"
                            pb={0}
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            {/* Plan Name heading */}
                            <Box m={0} p={'4px'} bg={'brand.neutral.bright'} borderRadius={'2px'}>
                                <Text fontSize={'sm'}>{primaryHeadDetail}</Text>
                            </Box>
                            {/* Plan Status Tag*/}
                            {status && (
                                <Box
                                    bgColor={statusMap[status].background}
                                    color={statusMap[status].color}
                                    borderRadius="2px"
                                    h={'full'}
                                    p="1"
                                >
                                    <Text fontSize={'sm'} fontWeight="400">
                                        {statusMap[status].text}
                                    </Text>
                                </Box>
                            )}
                        </HStack>
                    </Box>
                    {/* PPE Card Body */}
                    <Flex justify={'center'} w={'full'} p={'16px'} pt={0}>
                        <Box w={'full'}>
                            {fields.map((f, i) => {
                                return (
                                    <Stack
                                        display={'flex'}
                                        direction={'row'}
                                        gap={0}
                                        spacing={0}
                                        w={'full'}
                                        fontSize={'md'}
                                        fontWeight={400}
                                        key={`devicecard-field-${f.field}-${i}`}
                                    >
                                        <Box minWidth={'55px'} marginRight={'4px'}>
                                            {f.label}
                                        </Box>
                                        <Box fontWeight={f.weight || 400}>{args[`${f.field}`]}</Box>
                                    </Stack>
                                );
                            })}
                        </Box>
                        <Square>
                            {imageEl ? (
                                imageEl
                            ) : (
                                <Box m={'30% auto'} fontSize={'xs'} color={'gray.300'}>
                                    No image
                                </Box>
                            )}
                        </Square>
                    </Flex>
                    {/* Addtl Details Section */}
                    {!isDocuments && showMoreDetails && detailsEl && (
                        <Box w={'full'}>{detailsEl}</Box>
                    )}
                </VStack>
            );
        }

        // EWS Device Details
        if (isDeviceDetailsOn && deviceDetailsVersion && deviceDetailsVersion === 2) {
            const ewsFields: { field: string; label: string; weight?: number }[] = [
                { field: 'make', label: 'Brand:', weight: 700 },
                { field: 'model', label: 'Model:' },
                { field: 'serial', label: 'Serial:' },
                { field: 'warranty', label: 'OEM Warranty:' },
            ];

            const ewsStatusMap: Record<string, DeviceStatus> = {
                OEM_WARRANTY: {
                    id: 'oem_warranty',
                    text: 'OEM Warranty',
                    background: '#37E7A7',
                    color: 'black',
                },
                EWS: {
                    id: 'ews',
                    text: 'Extended Warranty',
                    background: '#37E7A7',
                    color: 'black',
                },
            };

            return (
                <VStack h="full" alignItems="start" gap={0}>
                    {/* PPE Card Header */}
                    <Box m={'0'} w="full" p={'16px'}>
                        <HStack
                            w="full"
                            h="full"
                            pb={0}
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            {/* Plan Name heading */}
                            <Box m={0} p={'4px'} bg={'brand.neutral.bright'} borderRadius={'2px'}>
                                <Text fontSize={'sm'}>{primaryHeadDetail}</Text>
                            </Box>
                            {/* Plan Status Tag*/}
                            {status && (
                                <Box
                                    bgColor={ewsStatusMap[status]?.background}
                                    color={ewsStatusMap[status]?.color}
                                    borderRadius="2px"
                                    h={'full'}
                                    p="1"
                                >
                                    {status === 'OEM_WARRANTY' && redirectUrl ? (
                                        <Link
                                            href={redirectUrl}
                                            textDecor="underline"
                                            target="_blank"
                                        >
                                            <Text fontSize={'sm'} fontWeight="400">
                                                <TextString
                                                    text={ewsStatusMap[status].text}
                                                    translationKey={`Global/EWSDeviceInfoStatus_Warranty_${ewsStatusMap[status].text}`}
                                                />
                                            </Text>
                                        </Link>
                                    ) : (
                                        <Text fontSize={'sm'} fontWeight="400">
                                            <TextString
                                                text={ewsStatusMap[status].text}
                                                translationKey={`Global/EWSDeviceInfoStatus_Warranty_${ewsStatusMap[status].text}`}
                                            />
                                        </Text>
                                    )}
                                </Box>
                            )}
                        </HStack>
                    </Box>
                    {/* PPE Card Body */}
                    <Flex justify={'center'} w={'full'} p={'16px'} pt={0}>
                        <Box w={'full'}>
                            {ewsFields.map((f, i) => {
                                return (
                                    <Stack
                                        display={'flex'}
                                        direction={'row'}
                                        gap={0}
                                        spacing={0}
                                        w={'full'}
                                        fontSize={'md'}
                                        fontWeight={400}
                                        key={`devicecard-field-${f.field}-${i}`}
                                    >
                                        <Box minWidth={'55px'} marginRight={'4px'}>
                                            <TextString
                                                text={f.label}
                                                translationKey={`Global/EWSDeviceInfoLabels_${f.label}`}
                                            />
                                        </Box>
                                        <Box fontWeight={f.weight || 400}>{args[`${f.field}`]}</Box>
                                    </Stack>
                                );
                            })}
                        </Box>
                        <Square>
                            {imageEl ? (
                                imageEl
                            ) : (
                                <Box m={'30% auto'} fontSize={'xs'} color={'gray.300'}>
                                    No image
                                </Box>
                            )}
                        </Square>
                    </Flex>
                    {/* Addtl Details Section */}
                    {!isDocuments && showMoreDetails && detailsEl && (
                        <Box w={'full'}>{detailsEl}</Box>
                    )}
                </VStack>
            );
        }

        // Render Default
        return (
            <>
                <Flex
                    justify={{ base: 'center', lg: hasUpgrade ? 'space-between' : 'space-between' }}
                    h={isLoading ? '283px' : 'auto'}
                >
                    <Box w={{ base: 'full' }}>
                        <Box id="upper-device-card" p="16px">
                            <Box
                                m={'0'}
                                mb={'15px'}
                                w="full"
                                aria-description="plan details row for device selection"
                                id="boxxy"
                            >
                                <HStack
                                    w="full"
                                    h="full"
                                    pb={0}
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                >
                                    <Skeleton isLoaded={!isLoading} minH="29px">
                                        <Box
                                            m={0}
                                            p={'4px'}
                                            w={'130px'}
                                            bg={'brand.neutral.bright'}
                                            borderRadius={'2px'}
                                            minH={'29px'}
                                        >
                                            <Text fontSize={'sm'}>{primaryHeadDetail}</Text>
                                        </Box>
                                    </Skeleton>
                                    {status && status !== 'approved' && (
                                        <Skeleton
                                            isLoaded={!isLoading}
                                            display={{ base: 'block', lg: 'none' }}
                                        >
                                            <Box
                                                bgColor={statusMap[status]?.background}
                                                color={statusMap[status]?.color}
                                                borderRadius="2px"
                                                h={'full'}
                                                p="1"
                                                display={{ base: 'block', lg: 'none' }}
                                            >
                                                <Text fontSize={'sm'} fontWeight="400">
                                                    {statusMap[status].text}
                                                </Text>
                                            </Box>
                                        </Skeleton>
                                    )}
                                </HStack>
                            </Box>
                            {status && (
                                <Box w="full" display={{ base: 'none', lg: 'block' }}>
                                    <HStack
                                        w="full"
                                        h="full"
                                        pb={0}
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        py={4}
                                    >
                                        {activeSRNumber && (
                                            <Skeleton isLoaded={!isLoading}>
                                                <Box
                                                    m={0}
                                                    p={'4px'}
                                                    w={'130px'}
                                                    bg={'brand.neutral.bright'}
                                                    borderRadius={'2px'}
                                                >
                                                    <Text fontSize={'sm'}>
                                                        {activeSRNumber
                                                            ? `ID: ${activeSRNumber}`
                                                            : 'ID: ---'}
                                                    </Text>
                                                </Box>
                                            </Skeleton>
                                        )}
                                        {status && status !== 'approved' && (
                                            <Skeleton isLoaded={!isLoading}>
                                                <Box
                                                    bgColor={statusMap[status].background}
                                                    color={statusMap[status].color}
                                                    borderRadius="2px"
                                                    h={'full'}
                                                    p="1"
                                                    display={{ base: 'none', lg: 'block' }}
                                                >
                                                    <Text fontSize={'sm'} fontWeight="400">
                                                        {statusMap[status].text}
                                                    </Text>
                                                </Box>
                                            </Skeleton>
                                        )}
                                    </HStack>
                                </Box>
                            )}
                            <Flex
                                justify={{
                                    base: isLoading ? 'space-between' : 'center',
                                    lg:
                                        hasUpgrade || use === 'planOverview'
                                            ? 'center'
                                            : 'space-between',
                                }}
                                w="full"
                                direction={{
                                    base: 'row',
                                    lg:
                                        hasUpgrade || use === 'planOverview'
                                            ? 'row'
                                            : 'row-reverse',
                                }}
                            >
                                {/* This is device details */}
                                <Box
                                    w={
                                        // isLoading
                                        //     ? '60%'
                                        //     : hasUpgrade || use === 'planOverview'
                                        //     ? 'full'
                                        //     : ''
                                        'full'
                                    }
                                    display="flex"
                                    flexFlow={{
                                        base: 'column',
                                        lg: 'row',
                                        sm: 'column',
                                        // lg: hasUpgrade || use === 'planOverview' ? 'column' : 'row',
                                    }}
                                    gap={{
                                        base: 0,
                                        lg: hasUpgrade || use === 'planOverview' ? 0 : '16px',
                                    }}
                                    justifyContent="space-between"
                                >
                                    {(isLoading
                                        ? [
                                              { field: 'Field', label: 'Label' },
                                              { field: 'Field', label: 'Label' },
                                              { field: 'Field', label: 'Label' },
                                          ]
                                        : fields
                                    ).map((f, i) => {
                                        return (
                                            <Fragment key={`${f.label}-${i}`}>
                                                {i === 0 ? (
                                                    <HStack alignItems="start">
                                                        <Skeleton
                                                            isLoaded={!isLoading}
                                                            mr={
                                                                isLoading
                                                                    ? {
                                                                          base: 0,
                                                                          md: hasUpgrade ? 0 : 2,
                                                                      }
                                                                    : 0
                                                            }
                                                            ml={isLoading ? { base: 2, md: 0 } : 0}
                                                            display={{ base: 'none', lg: 'block' }}
                                                        >
                                                            <Square
                                                                size={'max-content'}
                                                                marginLeft={'5px'}
                                                            >
                                                                {imageEl ? (
                                                                    imageEl
                                                                ) : (
                                                                    <Skeleton
                                                                        isLoaded={!isLoading}
                                                                        mr={isLoading ? 2 : 0}
                                                                    >
                                                                        <Box
                                                                            m={'30% auto'}
                                                                            fontSize={'xs'}
                                                                            color={'gray.300'}
                                                                        >
                                                                            No image
                                                                        </Box>
                                                                    </Skeleton>
                                                                )}
                                                            </Square>
                                                        </Skeleton>
                                                        <Stack
                                                            direction={{
                                                                base: 'column',
                                                                xsm: 'row',
                                                                lg: 'column',
                                                                // lg:
                                                                //     hasUpgrade ||
                                                                //     use === 'planOverview'
                                                                //         ? 'row'
                                                                //         : 'column',
                                                            }}
                                                            gap={0}
                                                            spacing={0}
                                                            w={'full'}
                                                            fontSize={'md'}
                                                            fontWeight={400}
                                                            key={`devicecard-field-${f.field}-${i}`}
                                                        >
                                                            <Box
                                                                w={{
                                                                    base: 'full',
                                                                    xsm: '30%',
                                                                    md:
                                                                        hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '30%'
                                                                            : '30%',
                                                                    lg:
                                                                        hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? 'full'
                                                                            : 'full',
                                                                }}
                                                                minW={{
                                                                    base: '30%',
                                                                    xl:
                                                                        hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '20%'
                                                                            : 'full',
                                                                }}
                                                                float={{
                                                                    base: 'left',
                                                                    xl:
                                                                        hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? 'none'
                                                                            : 'left',
                                                                }}
                                                            >
                                                                <Skeleton
                                                                    isLoaded={!isLoading}
                                                                    mb={isLoading ? 2 : 0}
                                                                    mr={
                                                                        isLoading
                                                                            ? { base: 2 }
                                                                            : { base: 0 }
                                                                    }
                                                                    height={
                                                                        isLoading ? '20px' : 'auto'
                                                                    }
                                                                    w={{
                                                                        base: 'full',
                                                                        sm: 'full',
                                                                        lg: 'full',
                                                                    }}
                                                                >
                                                                    <Text
                                                                        fontWeight={{
                                                                            lg:
                                                                                hasUpgrade ||
                                                                                use ===
                                                                                    'planOverview'
                                                                                    ? 'normal'
                                                                                    : 'bold',
                                                                        }}
                                                                    >
                                                                        <TextScript
                                                                            text={f.label}
                                                                            translationKey={`DynamicDeviceCard/${f.field}`}
                                                                        />
                                                                    </Text>
                                                                </Skeleton>
                                                            </Box>

                                                            <Box
                                                                w={{
                                                                    base: '70%',
                                                                    sm:
                                                                        hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '70%'
                                                                            : 'full',
                                                                    xl:
                                                                        hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? 'full'
                                                                            : 'full',
                                                                }}
                                                                float={{
                                                                    base: 'right',
                                                                    xl:
                                                                        hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? 'none'
                                                                            : 'right',
                                                                }}
                                                                maxW={{
                                                                    base: 'none',
                                                                    xl:
                                                                        hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '200px'
                                                                            : 'none',
                                                                }}
                                                                minW={{
                                                                    base: '0',
                                                                    lg:
                                                                        hasUpgrade ||
                                                                        use === 'planOverview'
                                                                            ? '0px'
                                                                            : '200px',
                                                                }}
                                                                fontWeight={f.weight || 400}
                                                            >
                                                                <Skeleton
                                                                    isLoaded={!isLoading}
                                                                    height={
                                                                        isLoading ? '20px' : 'auto'
                                                                    }
                                                                    w={'full'}
                                                                >
                                                                    {f.field === 'mdn'
                                                                        ? formatMdnString(
                                                                              args[`${f.field}`]
                                                                          )
                                                                        : args[`${f.field}`] ??
                                                                          '--'}
                                                                </Skeleton>
                                                            </Box>
                                                        </Stack>
                                                    </HStack>
                                                ) : (
                                                    <Stack
                                                        direction={{
                                                            base: 'column',
                                                            xsm: 'row',
                                                            lg: 'column',
                                                            // lg:
                                                            //     hasUpgrade || use === 'planOverview'
                                                            //         ? 'row'
                                                            //         : 'column',
                                                        }}
                                                        gap={0}
                                                        spacing={0}
                                                        fontSize={'md'}
                                                        fontWeight={400}
                                                        key={`devicecard-field-${f.field}-${i}`}
                                                        // justifyContent="center"
                                                    >
                                                        <Box
                                                            w={{
                                                                base: '30%',
                                                                xl:
                                                                    hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? '20%'
                                                                        : 'full',
                                                            }}
                                                            minW={{
                                                                base: '30%',
                                                                xl:
                                                                    hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? '20%'
                                                                        : 'full',
                                                            }}
                                                            // w="auto"
                                                            float={{
                                                                base: 'left',
                                                                xl:
                                                                    hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? 'none'
                                                                        : 'left',
                                                            }}
                                                        >
                                                            <Skeleton
                                                                isLoaded={!isLoading}
                                                                mb={isLoading ? 2 : 0}
                                                                mr={
                                                                    isLoading
                                                                        ? { base: 2 }
                                                                        : { base: 0 }
                                                                }
                                                                height={isLoading ? '20px' : 'auto'}
                                                                w={'56px'}
                                                            >
                                                                <Text
                                                                    // fontWeight={{
                                                                    //     lg:
                                                                    //         hasUpgrade ||
                                                                    //         use === 'planOverview'
                                                                    //             ? 'normal'
                                                                    //             : 'bold',
                                                                    // }}
                                                                    fontWeight="bold"
                                                                >
                                                                    <TextScript
                                                                        text={f.label}
                                                                        translationKey={`DynamicDeviceCard/${f.field}`}
                                                                    />
                                                                </Text>
                                                            </Skeleton>
                                                        </Box>

                                                        <Box
                                                            // w={{
                                                            //     base: '70%',
                                                            //     xl:
                                                            //         hasUpgrade ||
                                                            //         use === 'planOverview'
                                                            //             ? '70%'
                                                            //             : 'full',
                                                            // }}
                                                            w="full"
                                                            float={{
                                                                base: 'right',
                                                                xl:
                                                                    hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? 'none'
                                                                        : 'right',
                                                            }}
                                                            maxW={{
                                                                base: 'none',
                                                                xl:
                                                                    hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? '200px'
                                                                        : 'none',
                                                            }}
                                                            minW={{
                                                                base: '0',
                                                                lg:
                                                                    hasUpgrade ||
                                                                    use === 'planOverview'
                                                                        ? '0px'
                                                                        : '200px',
                                                            }}
                                                            fontWeight={f.weight || 400}
                                                        >
                                                            <Skeleton
                                                                isLoaded={!isLoading}
                                                                height={isLoading ? '20px' : 'auto'}
                                                                w={'full'}
                                                            >
                                                                {f.field === 'mdn'
                                                                    ? formatMdnString(
                                                                          args[`${f.field}`]
                                                                      )
                                                                    : args[`${f.field}`] ?? '--'}
                                                            </Skeleton>
                                                        </Box>
                                                    </Stack>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                </Box>

                                {/* This is mobile image  */}
                                <Skeleton
                                    isLoaded={!isLoading}
                                    mr={isLoading ? { base: 0, md: hasUpgrade ? 0 : 2 } : 0}
                                    ml={isLoading ? { base: 2, md: 0 } : 0}
                                >
                                    <Square
                                        size={'max-content'}
                                        marginLeft={'5px'}
                                        display={{ base: 'block', lg: 'none' }}
                                    >
                                        {imageEl ? (
                                            imageEl
                                        ) : (
                                            <Skeleton isLoaded={!isLoading} mr={isLoading ? 2 : 0}>
                                                <Box
                                                    m={'30% auto'}
                                                    fontSize={'xs'}
                                                    color={'gray.300'}
                                                >
                                                    No image
                                                </Box>
                                            </Skeleton>
                                        )}
                                    </Square>
                                </Skeleton>
                            </Flex>
                        </Box>

                        {!isDocuments && showMoreDetails && detailsEl && (
                            <Box w={'full'}>{detailsEl}</Box>
                        )}
                    </Box>
                </Flex>
            </>
        );

        // Return PPE

        // Return Default
    };

    return (
        <Box
            id={'myasurion-devicecard'}
            borderRadius={{ base: '8px', lg: 0 }}
            // borderBottomRadius={{ lg: '8px' }}
            minW={'286px'}
            minH={'124px'}
            borderWidth={'1px'}
            borderStyle={'solid'}
            borderColor={'#A5AAAF'}
            background={use === 'planOverview' ? 'initial' : '#F0F0F5'}
            borderBottom={{ base: '1px solid #A5AAAF' }}
            w={{ lg: 'full' }}
            {...props}
        >
            {renderCard()}
        </Box>
    );
};

export default DynamicDeviceCard;
