import { TextScript, ViewDocument } from '@MGPD/myasurion-shared/components';
import { Flex, Text, VStack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

import { DocumentFolders } from './constants';

export interface DocumentDetails {
    DocStatus: string;
    DocumentDescription: string;
    DocumentId: string;
    DocumentPath: string;
    DocumentRequiredStatus: string;
    DocumentType: string;
    EntityDocumentId: string;
    EntityId: string;
    EntityType: string;
    ExternalReferenceNumber: string;
    Filename: string;
    PageCount: string;
    ReceivedDate: string;
    ReceivedDocumentId: string;
    SignedUrl: string;
    Source: string;
    display: boolean;
}

interface DocumentListProps {
    documentList: DocumentDetails[];
    selectedFolder: (typeof DocumentFolders)[0];
    imei: string;
}

const DocumentList: FC<DocumentListProps> = ({ documentList = [], selectedFolder, imei }) => {
    const [requiredDocList, setRequiredDocList] = useState<DocumentDetails[]>([]);
    const [displayedDocList, setDisplayedDocList] = useState<DocumentDetails[]>([]);
    const [previewDocument, setPreviewDocument] = useState<DocumentDetails>();

    // NOTE: For testing purposes, remove once previewable S3 data is available
    const enableFiltering = true;

    const formatBillingDate = (dateIsoString: string) => {
        const d = new Date(dateIsoString);
        return `${d.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
        })}`;
    };

    const planBasedOffset = (dateIsoString: string, monthOffset: number, dayOffset: number) => {
        const d = new Date(dateIsoString);
        const mOffset = new Date(d.setMonth(d.getMonth() + monthOffset));
        const dOffset = new Date(mOffset.setDate(mOffset.getDate() + dayOffset));
        return formatBillingDate(dOffset.toISOString());
    };

    useEffect(() => {
        // NOTE: similar filtering logic was applied in TOM 1.0 MyDocuments
        if (enableFiltering) {
            setRequiredDocList(
                (documentList || []).filter(
                    (x) =>
                        x.DocumentType == 'INVOICE' &&
                        x.DocumentRequiredStatus == 'Y' &&
                        x.DocStatus == 'RECD'
                )
            );
        } else {
            setRequiredDocList(documentList);
        }
    }, [documentList]);

    useEffect(() => {
        // NOTE: similar filtering logic was applied in TOM 1.0 MyDocuments
        if (enableFiltering) {
            if (selectedFolder.id === 'Policy' && documentList.length) {
                const claimDoc = requiredDocList.filter((doc) => doc.EntityType === 'PLYNBR');
                setDisplayedDocList(claimDoc);
            } else if (selectedFolder.id === 'Claim' && documentList.length) {
                const claimDoc = requiredDocList.filter((doc) => doc.EntityType === 'SRVRQST');
                setDisplayedDocList(claimDoc);
            }
            setPreviewDocument(undefined);
        } else {
            setDisplayedDocList(documentList);
        }
    }, [selectedFolder, requiredDocList.length]);

    return (
        <>
            {previewDocument ? (
                <ViewDocument document={previewDocument} setPreviewDocument={setPreviewDocument} />
            ) : (
                <VStack gap={0}>
                    {displayedDocList.length === 0 ? (
                        <Flex h={20} alignItems="center">
                            <Text>
                                <TextScript
                                    text={'No documents to view'}
                                    translationKey={'DocumentList/NoDocuments'}
                                />
                            </Text>
                        </Flex>
                    ) : (
                        <>
                            {displayedDocList.map((doc, di) => (
                                <Flex
                                    w="100%"
                                    id={'document-list-item'}
                                    borderBottom="1px solid #d5d6da"
                                    direction="row"
                                    justifyContent="space-between"
                                    key={`${doc.DocumentId}-${di}`}
                                    onClick={() => setPreviewDocument(doc)}
                                    cursor="pointer"
                                >
                                    <Flex direction="column" py={5}>
                                        <Text
                                            fontSize="md"
                                            fontWeight="700"
                                            mb={2}
                                            lineHeight="shorter"
                                        >
                                            {`${selectedFolder.entryPrefix} ${formatBillingDate(
                                                doc.ReceivedDate
                                            )}`}
                                        </Text>
                                        {selectedFolder.id === 'Policy' ? (
                                            <Text fontSize="md" lineHeight="shorter">
                                                {`${formatBillingDate(
                                                    doc.ReceivedDate
                                                )} to ${planBasedOffset(doc.ReceivedDate, 1, -1)}`}
                                            </Text>
                                        ) : (
                                            <Text fontSize="md" lineHeight="shorter">
                                                {`IMEI ************${imei.slice(
                                                    -3
                                                )} (IMEI of swapped device)`}
                                            </Text>
                                        )}
                                    </Flex>
                                    {/* <Flex alignItems="center"> */}
                                    {/* TODO: modify display according to payment status */}
                                    {/* <Text fontSize="md" color="#008662">
                                            <TextScript
                                                text={'Paid'}
                                                translationKey={'Global/Paid'}
                                            />
                                        </Text> */}
                                    {/* </Flex> */}
                                </Flex>
                            ))}
                        </>
                    )}
                </VStack>
            )}
        </>
    );
};

export default DocumentList;
